/* EXPANDING SCREENSHOTS */

.titleBar {
  position: absolute;
  height: 15px;
  width: 50vw;
  z-index: 150;
  border: 1px solid #000;
  background-image: url('../img/dither-1.png');
  background-size: 16px;
  background-position: 0 1px;
}

.ExpandingWindow {
  border: 1px solid #000;
  max-width: 50vw;
  max-height: 50vh;
  width: auto;
  height: auto;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.ExpandingWindow.expanded {
  max-height: 10000px; 
}

.ScrollText {
  position: relative;
  left: calc(50vw + 20px);
  bottom: 68px;
  height: 0;
  overflow: visible;
  writing-mode: tb;
  font-size: 9px;
  line-height: 2px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 400;
  animation: scrollText-appear 1s ease-in;
}

.ScrollText.expanded {
  bottom: 84px;
}

@keyframes ScrollText-appear {
  from {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ExpandingWindow img {
  margin-top: 14px;
  left: 0;
  opacity: 1;
  animation: ExpandingWindow-appear 1 0.5s ease-in;
}

@keyframes ExpandingWindow-appear {
   from { opacity: 0 }
   to { opacity: 1 }
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( -o-min-device-pixel-ratio: 1.25/1),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 200dpi),
only screen and ( min-resolution: 1.25dppx)
{
  .titleBar {
    background-image: url("../img/dither-1@2x.png");
  }
}
