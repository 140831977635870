@import url('https://fonts.googleapis.com/css?family=Work+Sans:300,600');

body {
  /*   background-color: #000; */
  background-image: url("img/noise.png");
  background-size: 1024px;
  /*   padding-bottom: 80px; */
}

.App {
  min-width: 100vw;
  /*   min-height: 100vh; */
}

.projectsWrapper {
  overflow: hidden visible;
}

// leftover from create-react-app
.App-logo {
  /*
     animation: App-logo-spin infinite 20s linear;
     height: 80px;
   */
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

// shimmer effect -- uncomment last two lines
.Sea {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  /* background-image: url("img/dither-1.png");
     animation: Background-morph infinite 0.5s linear;
   */
}

@keyframes Background-morph {
  from { background-position: top left; }
  25% { background-position: top left 1px; } 
  50% { background-position: top 1px left 1px; } 
  75% { background-position: top 1px left 2px; } 
  to { background-position: top 2px left 2px; }
}

.Moon, .Shadow {
  width: 48px;
  height: 48px;
  position: fixed;
  right: 16px;
  bottom: 16px;
}

.Moon {
  background-image: url("img/dither-2.png");
  background-size: 16px;
  height: 48px;
  z-index: 100;
}

.Shadow {
  background-image: url("img/dither-5.png");
  background-size: 16px;
  z-index: -1;
}


@media screen and (min-width: 769px) {
  
}


/* ################ */
/* # PROJECT VIEW # */
/* ################ */

.ProjectView {
  position: fixed;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100vw;
  height: 100vh;
  padding: 16px;
}

.PreviousButton, .NextButton {
  position: fixed;
  width: 48px;
  height: 96px;
  bottom: 80px;
  background: #fff;
  border: 1px solid #000;
  z-index: 200;
}
.PreviousButton .text, .NextButton .text {
  display: block;
  position: relative;
  top: 48px;
} 

.PreviousButton {
  left: 0;
}

.PreviousButton .text {
  transform: rotate(-90deg);
}

.NextButton {
  right: 0;
}

.NextButton .text {
  transform: rotate(90deg);
}

/* SCROLLING WEBSITE THUMBNAIL */

.browser-window-background {
  position: absolute;
  top: 0;
  left: 0;
}

.img-wrap {
  width: 94px;
  height: 166px;
  -webkit-border-bottom-right-radius: 2px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  overflow: hidden;
  background-color: transparent;
  background-image: url("img/screenshot.png");
  background-repeat: no-repeat;
  background-size: 94px;
  background-position: 0 0;
}

/* ICONS */
.Icon-folder {
  background-image: url("img/icon-folder.png");
}

.Icon-ux {
  background-image: url("img/icon-ux.png");
}

.Icon-code {
  background-image: url("img/icon-code.png");
}

.Icon-graphic {
  background-image: url("img/icon-graphic.png");
}

.Icon-sound {
  background-image: url("img/icon-sound.png");
}

.Icon-studio {
  background-image: url("img/icon-studio.png");
}

.Icon-presentation {
  background-image: url("img/icon-presentation.png");
}

/* DITHER PATTERNS */
.ProjectNav, .ProjectList > ul {
  background-image: url("img/dither-1.png");
}

.ProjectList::before {
  background-image: url("img/dither-1-vertical-line@2x.png");
}

/* ############################ */
/* # HD IMAGES FOR HD SCREENS # */
/* ############################ */

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( -o-min-device-pixel-ratio: 1.25/1),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 200dpi),
only screen and ( min-resolution: 1.25dppx)
{
  body {
    background:url("img/noise@2x.png") repeat;
    background-size: 1024px;
  }
  
  .ProjectNav, .ProjectList > ul {
    background: url("img/dither-1@2x.png") repeat;
  }
  
  .Moon {
    background-image: url("img/dither-2@2x.png");
  }
  
  .Shadow {
    background-image: url("img/dither-5@2x.png");
  }
  
  .Icon-ux {
    background-image: url("img/icon-ux@2x.png");
  }

  .Icon-code {
    background-image: url("img/icon-code@2x.png");
  }

  .Icon-graphic {
    background-image: url("img/icon-graphic@2x.png");
  }

  .Icon-sound {
    background-image: url("img/icon-sound@2x.png");
  }

  .Icon-studio {
    background-image: url("img/icon-studio@2x.png");
  }

  .Icon-folder {
    background-image: url("img/icon-folder@2x.png");
  }

  .Icon-presentation {
    background-image: url("img/icon-presentation@2x.png");
  }

  .ProjectList::before {
    background-image: url("img/dither-1-vertical-line@2x.png");
  }
}
