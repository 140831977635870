.ProjectNav {
  width: calc(100vw - 32px);
  max-height: 10000px;
  position: relative;
  top: 52px;
  left: 16px;
  padding: 0 8px 2px 8px;
  border: 1px solid #000;
  background-color: #fff;
  background-repeat: repeat;
  background-size: 16px;
  background-position: top 1px left;
  background-attachment: local;
  z-index: 0;
  opacity: 1;
  margin-bottom: 136px;
  transition:
    opacity 0.3s ease-in,
    top 0.2s ease-in 0.1s,
    width 0.2s linear,
    max-height 0.2s linear;
}

.ProjectNav.fadeIn {
  animation: ProjectNav-appear 0.5s ease-in;
}

.ProjectNav.projectPage {
  position: absolute;
  width: calc(100% / 8 * 3);
  max-height: 0px;
  top: 96px;
  left: 0px;
  opacity: 0;
  overflow: hidden;
  /* transition:
     top 0.2s ease-in 0.2s,
     opacity 0.15s ease-out 0.05s,
     width 0.1s ease-in; */
}

@keyframes ProjectNav-appear {
  from{
    opacity: 0;
    top: 0;
  }
  50% {
    opacity: 0;
    top: 0;
  }
  to {
    opacity: 1;
    top: 1;
  }
}

.ProjectNav.projectPage .ProjectList > ul {
  padding-right: 4px;
}

.ProjectNav.projectPage .Moon, .ProjectNav.projectPage .Shadow {
  display: none;
}

@media screen and (min-width: 492px) {
  .ProjectNav {
    width: 460px;
  }
}

@media screen and (min-width: 532px) {
  .ProjectNav {
    /*     width: calc(100vw - 128px); */
  }
}

@media screen and (min-width: 568px) {
  .ProjectNav {
    /* transition:
       opacity 0.2s ease-in,
       top 0.2s ease-in 0.1s,
       left 0.2s ease-in; */
  }
  
  .ProjectNav.projectPage {
    top: 47px;
    opacity: 1;
    position: fixed;
    height: calc(100vh - 47px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background-attachment: scroll;
    border-bottom-color: transparent;
    left: 0;
    display: block;
    /*     transition: none; */
    max-height: 10000px;
    /* transition:
       opacity 0.2s ease-in,
       top 0.2s ease-in 0.1s,
       left 0.2s ease-in; */
  }
}

@media screen and (min-width: 682px) {
  .ProjectNav.projectPage {
    /*     width: 256px; */
  }
}

@media screen and (min-width: 912px) {
  .ProjectNav.projectPage {
    width: 342px;
  } 
}

.DataHeading {
  background-color: #fff;
  height: 14px;
  display: inline-block;
  margin: 5px 0 -1px 20px;
  padding: 6px 6px 0 6px;
}

.DataHeading * {
  font-size: 8px;
  line-height: 2px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 300;
  margin: 0;
  position: relative;
  top: 2px;
}
